import { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

export default function PageHeader({ children, label, isLoading }: {
    children?: ReactNode,
    label: string,
    isLoading?: boolean,
}) {

    return (
        <div className="w-full flex justify-between items-start mb-4 gap-10">
            <div className='flex flex-col justify-start items-start gap-1 grow'>
                {isLoading !== undefined && isLoading ? (
                    <div className="w-full"><Skeleton height={42}/></div>
                ) : (
                    <p className="font-semibold text-[2.5rem]">{label}</p>
                )}
            </div>
            <div className='flex gap-3'>
                {children}
            </div>
        </div>
    )
}