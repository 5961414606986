import { useEffect, useState, Dispatch, SetStateAction, MouseEvent } from "react";
import API from '../../api';
import { ModelStats, SingleClassificationModel, SingleRegressionModel } from "../../types/models.types";
import { useNavigate } from "react-router-dom";
import { Header, Limit, SideInfo } from "../../types/all.types";
import { convertDecimal, download, dsExists, getDatedFilename, isAdmin, isOwner, regTrColors, rgbColors, searchItems, sortByColumn, trColors } from "../../data/functions";
import { useSelector } from "react-redux";
import { selectDatasets } from "../../store/selectors/datasets.selector";
import { selectAuth } from "../../store/selectors/auth.selector";
import { deleteExistingModels } from "../../store/slices/models.slice";
import { useDispatch } from "react-redux";
import { classificationLimits, singleClassificationModelHeaders, singleRegressionModelHeaders } from "../../data/models";
import { selectUsers } from "../../store/selectors/users.selector";


export const isClassificationType = (obj:any): obj is SingleClassificationModel => {
    if (obj.auc !== undefined && obj.auc !== '') {
        return true;
    } else {
        return false;
    }
}

const useSingleModel = ({ name, setPopupMessage }: {
    name?: string,
    setPopupMessage?: (popupMessage: string, isSuccessMessage: boolean) => void,

}) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ singleModel, setSingleModel ] = useState<SingleClassificationModel[] | SingleRegressionModel[]>([]);
    const [ orderedRecords, setOrderedRecords ] = useState<any[]>([]);
    const [ modifiedRecords, setModifiedRecords ] = useState<any[]>([]);
    const [ info, setInfo ] = useState<SideInfo[]>([]);
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
    const [ allMethodIDs, setAllMethodIDs ] = useState<string[]>([]);
    const [ isClassification, setIsClassification ] = useState(false);
    const [ headers, setHeaders ] = useState<Header[]>([]);
    
    const [ methodPlots, setMethodPlots ] = useState<{ name: string, index: number, graphs: string[] }[]>([]);
    const [barData, setBarData] = useState<{x: string[], y: number[], name: string, type: string}[]>([]);
    const [ radarData, setRadarData ] = useState<{r: any[], theta: any[], name: string, type: string, fill: string}[]>([]);
    const [ stats, setStats ] = useState<ModelStats | null>(null);
    const [ limits, setLimits ] = useState<Limit[]>([]);
    const [ maxValue, setMaxValue ] = useState(1);


    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const datasets = useSelector(selectDatasets);
    const auth = useSelector(selectAuth);
    const users = useSelector(selectUsers);

    const findUserById = (id: string) => {
        const user = users.length > 0 && users.find(u => u._id.$oid === id);
        if (user) {
            return user.full_name
        } else {
            return ''
        }
    }


    useEffect(() => {
        if (name) {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {
                const fetchModel = async () => {

                    setIsLoading(true);

                    try {

                        const response = await API.get(`models/${name}`, { headers: { 'X-Auth': authKey } });
                        const data = await response.data;
                        
                        if (!data.length) {
                            throw Error();
                        } else {
                            setSingleModel(data);
                        }    

                    } catch (err:any) {

                        console.log(err);
                        navigate('/models/404')

                    }
                    
                    setIsLoading(false);
                    
                };
        
                fetchModel();

            } else {
                navigate('/401');
            }
            
        }
    }, [name, navigate]);


    useEffect(() => {
        if (singleModel && singleModel.length > 0) {

            let records: any[] = [];
            let isClassificationModel = false;
            let plots: { name: string, index: number, graphs: string[] }[] = [];

            if (isClassificationType(singleModel[0])) {
                
                isClassificationModel = true;
                setIsClassification(true);

                (singleModel as SingleClassificationModel[]).forEach((m: SingleClassificationModel) => {
                    records.push({
                        id: m._id.$oid,
                        method_name: m.method_name,
                        auc: convertDecimal(m.auc),
                        f1score: convertDecimal(m.f1score),
                        precision: convertDecimal(m.precision),
                        recall: convertDecimal(m.recall),
                        acc: convertDecimal(m.acc),
                        specificity: convertDecimal(m.specificity),
                        cohens_kappa: convertDecimal(m.cohens_kappa),
                        mcc: convertDecimal(m.mcc),
                        tp: m.tp,
                        tn: m.tn,
                        fp: m.fp,
                        fn: m.fn,
                    });

                    plots.push({
                        name: m.method_name,
                        index: 0,
                        graphs: ['overlay', 'roc', 'truthTable']
                    })
                })

            } else {

                isClassificationModel = false;
                setIsClassification(false);

                (singleModel as SingleRegressionModel[]).forEach((m: SingleRegressionModel) => {
                    records.push({
                        id: m._id.$oid,
                        method_name: m.method_name,
                        mae: convertDecimal(m.mae),
                        rmse: convertDecimal(m.rmse),
                        mpd: convertDecimal(m.mpd),
                        mgd: convertDecimal(m.mgd),
                        r2: convertDecimal(m.r2),
                    });

                    plots.push({
                        name: m.method_name,
                        index: 0,
                        graphs: ['scatter']
                    })
                })

            }

            setRoutes([
                {
                    label: 'Models',
                    path: 'models',
                },
                {
                    label: name ? name : 'Single Model',
                    path: name ? `models/${name}` : '',
                }
            ])

            setOrderedRecords(records);
            setModifiedRecords(records);
            setMethodPlots(plots);

            let params: {label: string, value: string}[] = [];
            let dsStats: {label: string, value: string}[] = [];

            singleModel[0].descriptors.forEach(d => {
                if (d.name === 'ECFP') {
                    if (d.params.Bits && d.params.Radius) {
                        params.push({label: 'ECFP Bits', value: d.params.Bits});
                        params.push({label: 'ECFP Radius', value: d.params.Radius});
                    } 
                } else if (d.name === 'FCFP') {
                    if (d.params.Bits && d.params.Radius) {
                        params.push({label: 'FCFP Bits', value: d.params.Bits});
                        params.push({label: 'FCFP Radius', value: d.params.Radius});
                    } 
                }
            });

            const splitOn = singleModel[0].dataset.fields_mapping.find(field => field.type === 'split-on-value');
            if (splitOn) {
                let valOp = '';
                if (splitOn.op === 'le') {
                    valOp = '<='
                } else if (splitOn.op === 'lt') {
                    valOp = '<'
                } else if (splitOn.op === 'ge') {
                    valOp = '>='
                } else {
                    valOp = '>'
                }

                dsStats.push({label: 'Split-On Threshold', value: `${valOp} ${splitOn.value}`});
            }

            if (singleModel[0].dataset.stats) {
                const stats = singleModel[0].dataset.stats;
                if (stats.high_value) {
                    dsStats.push({label: 'High Value', value: convertDecimal(stats.high_value)});
                }

                if (stats.low_value) {
                    dsStats.push({label: 'Low Value', value: convertDecimal(stats.low_value)});
                } 

                if (stats.actives) {
                    dsStats.push({label: 'Active Compounds', value: stats.actives.toString()})
                } 

                if (stats.inactives) {
                    dsStats.push({label: 'Inactive Compounds', value: stats.inactives.toString()})
                }
            }
            

            const dsInfo = [
                {
                    label: 'Dataset Name',
                    value: singleModel[0].dataset ? singleModel[0].dataset.name : '',
                    path: singleModel[0].dataset ? dsExists(singleModel[0].dataset._id.$oid, datasets) : undefined,
                },
                {
                    label: 'Model Type',
                    value: isClassificationModel ? 'Classification' : 'Regression'
                },
                {
                    label: 'Model Label',
                    value: singleModel[0].label
                },
                {
                    label: 'Descriptors',
                    value: (singleModel[0].descriptors.map(d => d.name)).join(', '),
                },
            ];

            const aclInfo = [
                {
                    label: 'Created By',
                    value: singleModel[0].created_by,
                },
                {
                    label: 'Owner',
                    value: singleModel[0].acl ? findUserById(singleModel[0].acl.owner) : '',
                    key: auth && (isAdmin(auth) || isOwner(auth, singleModel[0].acl.owner)) ? 'owner' : undefined,
                    type: 'select',
                },
                {
                    label: 'Access Type',
                    value: singleModel[0].acl.access,
                    key: auth && (isAdmin(auth) || isOwner(auth, singleModel[0].acl.owner)) ? 'access' : undefined,
                    type: 'select',
                },
            ]


            setInfo(dsInfo.concat(params).concat(dsStats).concat(aclInfo));

        }
         
        //eslint-disable-next-line
    }, [singleModel, name, auth, datasets]);


    useEffect(() => {
        setHeaders(isClassification ? singleClassificationModelHeaders : singleRegressionModelHeaders);
    }, [isClassification]);


    useEffect(() => {
        if (modifiedRecords.length > 0) {
            setAllMethodIDs(modifiedRecords.map(r => r.id))
        }
    }, [modifiedRecords])



    const modifyRecords = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>) => {
        const searchArr = searchItems(searchInput, orderedRecords, 'method_name');
        const sortedArr = sortByColumn(selectedHeader, headers, true, searchArr, updateHeaders);
        setModifiedRecords(sortedArr);
    }

    const updateModel = async (obj: any) => {
        const aclObj = {owner: obj.owner, access: obj.access, read: [], write: []};
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            try {

                if (auth && singleModel && (isAdmin(auth) || isOwner(auth, singleModel[0].acl.owner))) {
                    await API.put(`models/${name}/acl`, aclObj, { headers: { 'X-Auth': authKey } })
                }
                const getResponse = await API.get(`models/${name}`, { headers: { 'X-Auth': authKey } });
                const data = getResponse.data;

                setPopupMessage && setPopupMessage('This model was updated successfully!', true);
                setSingleModel && setSingleModel(data);
    
            } catch(err:any) {
    
                console.log(err);
                setPopupMessage && setPopupMessage('There was an error updating this model', false);
    
            }
        }
    }

    const downloadModels = async (names: string[], name?: string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {

                const response = await API.post(`models/download`, { names: names, ids: []} ,{ headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                let filename = '';
                if (name !== undefined) {
                    filename = `${name}.xlsx`;
                } else {
                    filename = getDatedFilename('models', 'xlsx');
                } 

                download(filename, data);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error downloading ${names.length > 1 ? 'these models' : 'this model'}`, false);
            }
        }
    };

    const deleteModels = async (names: string[], modelName?: string) => {
        const authKey = localStorage.getItem('X-Auth');

        const promises = names.map(async (name) => {
            const encoded = encodeURI(name);
            if (!modelName) {
                return API.delete(`models/${encoded}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingModels(name)));      
            } else {
                return API.delete(`models/${modelName}/${name}`, { headers: { 'X-Auth': authKey } })
            }
        })
        if (authKey) {
            try {
                await Promise.all(promises);
            } catch(err:any) {
                setPopupMessage && setPopupMessage(`There was an error deleting ${modelName ? names.length > 1 ? 'these models' : 'this model' : names.length > 1 ? 'these models' : 'this model'}`, false);
                throw new Error('Error');
            }

            if (modelName) {
                const response = await API.get(`models/${modelName}`, { headers: { 'X-Auth': authKey } });
                const data: SingleClassificationModel[] | SingleRegressionModel[] = response.data;
                setSingleModel && setSingleModel(data);
            }
        }
    };

    const next = (e:MouseEvent<HTMLButtonElement>, name: string) => {
        e.preventDefault();
        const method = methodPlots.find(plot => plot.name === name);
        if (method) {
            if (method.index === method.graphs.length-1) {
                return;
            } else {
                setMethodPlots(methodPlots.map(plot => {
                    if (plot.name === name) {
                        return {...plot, index: plot.index+1}
                    } else {
                        return {...plot}
                    }
                }))
            }
        }
    }

    const back = (e:MouseEvent<HTMLButtonElement>, name: string) => {
        e.preventDefault();
        const method = methodPlots.find(plot => plot.name === name);
        if (method) {
            if (method.index === 0) {
                return;
            } else {
                setMethodPlots(methodPlots.map(plot => {
                    if (plot.name === name) {
                        return {...plot, index: plot.index-1}
                    } else {
                        return {...plot}
                    }
                }))
            }
        }

    };

    useEffect(() => {
        if (modifiedRecords.length > 0 && !isClassification) {
            let mae: number[] = [];
            let rmse: number[] = [];

            modifiedRecords.forEach(record => {
                Object.keys(record).forEach(key => {
                    if (key === 'mae') {
                        mae.push(Number(record[key]))
                    } else if (key === 'rmse') {
                        rmse.push(Number(record[key]))
                    }
                })
            })


            const maxMae = Math.max(...mae);
            const maxRmse = Math.max(...rmse);

            if (maxMae > maxRmse) {
                setMaxValue(maxMae)
            } else {
                setMaxValue(maxRmse)
            }

            const newLimits: Limit[] = [
                {
                    value: 'r2',
                    min: 0,
                    max: 1,
                    isReversed: false,
                },
                {
                    value: 'mae',
                    min: 0,
                    max: maxMae,
                    isReversed: false,
                },
                {
                    value: 'rmse',
                    min: 0,
                    max: maxRmse,
                    isReversed: false,
                }
            ]

            setLimits(newLimits)

            
        } else {
            setLimits(classificationLimits);

        }
    }, [modifiedRecords, isClassification]);


    useEffect(() => {

        if (singleModel.length > 0) {

            const sorted = singleModel.sort((a,b) => {
                if (a.method_name.toLowerCase() < b.method_name.toLowerCase()) {
                    return -1
                } else if (a.method_name.toLowerCase() > b.method_name.toLowerCase()) {
                    return 1
                } else {
                    return 0
                }
            })

            let methodArr: string[] = [];
            // let otherMethodArr: string[] = [];


            if (isClassification) {

                methodArr = ['auc', 'acc', 'cohens_kappa', 'f1score', 'mcc', 'precision', 'recall', 'specificity'];

                const arr = sorted.map(m => ({
                    x: methodArr,
                    y: methodArr.map(method => m[method as keyof object]),
                    name: m.method_name,
                    type: 'bar',
                    marker: {
                        color: trColors[m.method_name as keyof object],
                    }
                }));
    
                const radarArr = sorted.map(m => ({
                    type: 'scatterpolar',
                    r: methodArr.map(method => m[method as keyof object]),
                    theta: methodArr,
                    fill: 'toself',
                    name: m.method_name,
                    marker: {
                        color: trColors[m.method_name as keyof object],
                    },
                    fillcolor: rgbColors[m.method_name as keyof object],
                }));

                setRadarData(radarArr);
                setBarData(arr);
                
            } else {
                methodArr = ['r2', 'mae', 'rmse'];
                // otherMethodArr = ['mae', 'rmse'];

                const traceArr = methodArr.map(method => ({
                    x: singleModel.map(m => m.method_name),
                    y: singleModel.map(m => m[method as keyof object]),
                    name: method,
                    type: 'bar',
                    marker: {
                        color: regTrColors[method as keyof object],
                    }
                }));
    

                setBarData(traceArr);
                // setOtherBarData(otherTraceArr)
            }

            if (singleModel[0].dataset) {
                const ds = singleModel[0].dataset;
                setStats({
                    records_number: ds.records_number,
                    stats: ds.stats,
                })
            }
    
        }

    }, [singleModel, isClassification]);
    


    return {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        headers,
        setHeaders,
        info,
        routes,
        downloadModels,
        allMethodIDs, 
        updateModel,
        deleteModels,
        isLoading,
        isClassification,
        singleModel,
        methodPlots,
        back,
        next,
        stats,
        barData,
        radarData,
        limits,
        maxValue
    }

}

export default useSingleModel;