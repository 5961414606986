import ThresholdSlider from "../../../components/Data/ThresholdSlider";
import Grid from "../../../components/Grid/Grid";
import GridItemBase from "../../../components/Grid/GridItemBase";
import PlotlyGraphs from "../../../components/Data/PlotlyGraphs";
import { Dispatch, SetStateAction } from "react";
export default function ConfPredMethods({ 
    threshold,
    setThreshold,
    plotData,
    total,
}: {
    threshold: number,
    setThreshold: Dispatch<SetStateAction<number>>,
    plotData: { data: Plotly.Data[], methods: string[] }[],
    total: number,
}) {
    return (
        <div style={{maxHeight: 'calc(100vh - 70px)'}} className="h-full overflow-hidden flex flex-col justify-start items-stretch gap-4">

            <ThresholdSlider threshold={threshold} setThreshold={setThreshold}/>


            <Grid isLoading={false}>
                {plotData.length > 0 && plotData.map((plot, i) => (
                    <GridItemBase isChecked={false} gridType="structure">
                        <PlotlyGraphs data={plot.data} type="conf" isSquare={true} methodName={plot.methods ? plot.methods[i] : ''} total={total}/>
                    </GridItemBase>
                ))}
            </Grid>
        </div>
    )
}