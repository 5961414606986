import { ReactNode, useEffect, useState } from "react"
import { SingleDatasetType } from "../../types/datasets.types";
import RecordsIcon from '../../assets/data/records.svg'
import ThresholdIcon from '../../assets/data/threshold.svg';
import ActivesIcon from '../../assets/data/grey_check.svg';
import InactivesIcon from '../../assets/data/grey_x.svg';
import LowIcon from '../../assets/arrows/arrow-down-right.svg';
import HighIcon from '../../assets/arrows/arrow-up-right.svg';
import { convertDecimal } from "../../data/functions";

export default function MetaData({ records, actives, inactives, high, low, fields_mapping }: {
    records: number,
    actives?: number,
    inactives?: number,
    high?: number,
    low?: number,
    fields_mapping?: any[],
}) {
    const [ metadata, setMetadata ] = useState<{
        title: string,
        value: number | string,
        icon: ReactNode,
    }[]>([]);


    useEffect(() => {
        let data: {
            title: string,
            value: number | string,
            icon: ReactNode,
        }[] = [];

        if (records !== undefined) {
            data.push({title: 'Records:', value: records, icon: <img src={RecordsIcon} alt='records_icon' className="w-[16px] opacity-75"/>});
        }

        if (high !== undefined ) {
            data.push({title: 'High-Value:', value: convertDecimal(high), icon: <img src={HighIcon} alt='high-value-icon' className="w-[18px] opacity-75"/>});
        }

        if (low !== undefined ) {
            data.push({title: 'Low-Value:', value: convertDecimal(low), icon: <img src={LowIcon} alt='low-value-icon' className="w-[18px] opacity-75"/>});
        }

        if (actives !== undefined ) {
            data.push({title: 'Actives:', value: actives, icon: <img src={ActivesIcon} alt='actives-icon' className="w-[18px] opacity-75"/>});
        }

        if (inactives !== undefined ) {
            data.push({title: 'Inactives:', value: inactives, icon: <img src={InactivesIcon} alt='inactives-icon' className="w-[18px] opacity-75"/>});
        }

        if (fields_mapping !== undefined) {
            const splitOn = fields_mapping.find(field => field.type === 'split-on-value');
            if (splitOn) {
                let valOp = '';
                if (splitOn.op === 'le') {
                    valOp = '<='
                } else if (splitOn.op === 'lt') {
                    valOp = '<'
                } else if (splitOn.op === 'ge') {
                    valOp = '>='
                } else {
                    valOp = '>'
                }

                data.push({title: 'Threshold:', value: `${valOp} ${splitOn.value}`, icon: <img src={ThresholdIcon} alt='threshold-icon' className="w-[20px] opacity-75" />})
            }
        }

        setMetadata(data);

    }, []);



    return (
        <>
        {metadata.length > 0 && (
            <div className="flex justify-start items-center gap-6">
                {metadata.map(data => (
                    <div className="flex justify-start items-center gap-2">
                        <div className="flex justify-start items-center gap-1">
                            {data.icon}
                            <span className="font-semibold text-quaternary">{data.title}</span>
                        </div>
                        <span className="font-semibold text-secondary">{data.value}</span>
                    </div>
                ))}
            </div>
        )}
        </>
    )
}