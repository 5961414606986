import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useState, MouseEvent, useEffect } from "react";

import ACLogo from '../assets/assay-central-logo.png';
import MTLogo from '../assets/megatox.png';
import MTRLogo from '../assets/megatrans.png';
import ChevronLeft from '../assets/chevron-left-black.svg';
import FilesIcon from '../assets/file.svg';
import DatasetsIcon from '../assets/dataset.svg';
import ModelsIcon from '../assets/model.svg';
import PredictionsIcon from '../assets/predictions.svg';
import JobsIcon from '../assets/jobs.svg';
import UsersIcon from '../assets/users.svg';
import HelpIcon from '../assets/help.svg';
import AccountIcon from '../assets/user1.svg';
import LogoutIcon from '../assets/logout.svg';
import ReadAcrossIcon from '../assets/beaker.svg';

import { useDispatch, useSelector } from "react-redux";
import { selectNav } from "../store/selectors/nav.selector";
import { setSelectedFile } from "../store/slices/files.slice";
import { setSelectedDataset } from "../store/slices/datasets.slice";
import { toggleNav } from "../store/slices/nav.slice";
import { selectPrevLocation } from "../store/selectors/location.selector";
import { setPrevPath } from "../store/slices/location.slice";
import useAuth from "../hooks/useAuth";
import { setSelectedModel } from "../store/slices/models.slice";
import { isAdmin } from "../data/functions";
import { selectApplication, selectAuth, selectMetadata, selectTheme } from "../store/selectors/auth.selector";
import { Tooltip } from "@mui/material";
import SnackBar from "./Alerts/SnackBar";
import useMessage from "../hooks/useMessage";


export default function Navigation() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useSelector(selectAuth);
    const metadata = useSelector(selectMetadata);
    const application = useSelector(selectApplication);
    const theme = useSelector(selectTheme);

    const isNavOpen = useSelector(selectNav);
    const prevPath = useSelector(selectPrevLocation);
    const [ isNavVisible, setIsNavVisible ] = useState(true);
    const [ pathName, setPathname ] = useState('');
    const { open, setOpen } = useMessage();
    const { logout, errorMessage } = useAuth({setErrorCredentials: undefined, setOpen});
    
    
    const [ isReadacrossEnabled, setIsReadacrossEnabled ] = useState(false);

    useEffect(() => {
        if (metadata) {
            if (metadata.config.readacross === 'true') {
                setIsReadacrossEnabled(true);
            } else {
                setIsReadacrossEnabled(false);
            }
        }

    }, [metadata]);


    
    useEffect(() => {
        const path = location.pathname.replace('/', '');
        setPathname(path);

        // removes navigation bar from login screen
        if (path.length) {
            setIsNavVisible(true);
        } else {
            setIsNavVisible(false);
        }

        // resets file, dataset and model selection when navigating out of create-new-model or make-new-prediction pages
        if (prevPath.includes('create-new-model') && !path.includes('create-new-model')) {
            dispatch(setSelectedDataset(null));
            dispatch(setSelectedFile(null));
        } else if (prevPath.includes('make-new-prediction') && !path.includes('make-new-prediction')) {
            dispatch(setSelectedFile(null));
            dispatch(setSelectedModel(null));
        }

        dispatch(setPrevPath(path));

    },[location.pathname, prevPath, dispatch]);

    return (
        <div className={`min-h-screen flex justify-start items-start bg-darker-background`}>
            {isNavVisible && (
                <div id='navbar' className={`${!isNavOpen && 'shrink'} flex-[0_0_auto] sticky top-0 min-h-screen bg-secondary p-5 rounded-tr rounded-br flex flex-col justify-between items-start text-white`}>
                    <div className='flex flex-col gap-4 justify-center items-stretch'>
                        <div className='mb-2 relative'>
                            
                                <div id='logo-container' className='flex justify-start items-center gap-2 hide'>
                                    {application && application === 'megatox' ? (
                                        <>
                                            <img id='logo' src={MTLogo} className="w-[38px] ml-[4px]" alt='assay-central-logo' />
                                            <p className="text-[1.5rem] font-semibold text-nowrap mr-4">MegaTox</p>
                                        </>
                                    ) : application && application === 'megatrans' ? (
                                        <>
                                            <img id='logo' src={MTRLogo} className="w-[38px] ml-[4px]" alt='assay-central-logo' />
                                            <p className="text-[1.5rem] font-semibold text-nowrap mr-4">MegaTrans</p>
                                        </>
                                    ) : (
                                        <>
                                            <img id='logo' src={ACLogo} className="w-[38px] ml-[4px]" alt='assay-central-logo' />
                                            <p className="text-[1.5rem] font-semibold text-nowrap mr-4">Assay Central</p>
                                        </>
                                    )}
                                </div>
                            
                            <button onClick={(e:any) => {dispatch(toggleNav(isNavOpen))}} className="whiten p-[6px] rounded-full aspect-square bg-tertiary darken absolute right-[-32px] top-[2px] z-50">
                                <img src={ChevronLeft} id='navbutton' alt='close-nav-icon' className='w-[16px]'/>
                            </button>
                        </div>

                        <>

                        <Tooltip title={`${!isNavOpen ? 'Files' : ''}`} placement="right">
                            <button id='files-nav' onClick={(e:MouseEvent<HTMLButtonElement>) => navigate('/files')} className={`hide ${pathName === 'files' ? 'bg-tertiary text-primary darken-icons' : 'bg-none text-white'} transition-all darken flex items-center gap-3 p-3 hover:text-primary hover:bg-tertiary rounded`}>
                                <img src={FilesIcon} alt='files-icon' className='w-[24px]'/>
                                <p className="font-medium">Files</p>
                            </button>
                        </Tooltip>
                        
                        {application && (application).includes('assay-central') && (
                            <Tooltip title={`${!isNavOpen ? 'Datasets' : ''}`} placement="right">
                                <button onClick={(e:MouseEvent<HTMLButtonElement>) => navigate('/datasets')} className={`hide ${pathName === 'datasets' ? 'bg-tertiary text-primary darken-icons' : 'bg-none text-white'} transition-all darken flex items-center gap-3 p-3 hover:text-primary hover:bg-tertiary rounded`}>
                                    <img src={DatasetsIcon} alt='datasets-icon' className='w-[24px]'/>
                                    <p className="font-medium">Datasets</p>
                                </button>
                            </Tooltip>
                        )}

                        <Tooltip title={`${!isNavOpen ? 'Models' : ''}`} placement="right">
                            <button onClick={(e:MouseEvent<HTMLButtonElement>) => navigate('/models')} className={`hide ${pathName === 'models' ? 'bg-tertiary text-primary darken-icons' : 'bg-none text-white'} transition-all darken flex items-center gap-3 p-3 hover:text-primary hover:bg-tertiary rounded`}>
                                <img src={ModelsIcon} alt='models-icon' className='w-[24px]'/>
                                <p className="font-medium">Models</p>
                            </button>
                        </Tooltip>

                        <Tooltip title={`${!isNavOpen ? 'Predictions' : ''}`} placement="right">
                            <button onClick={(e:MouseEvent<HTMLButtonElement>) => navigate('/predictions')} className={`hide ${pathName === 'predictions' ? 'bg-tertiary text-primary darken-icons' : 'bg-none text-white'} transition-all darken flex items-center gap-3 p-3 hover:text-primary hover:bg-tertiary rounded`}>
                                <img src={PredictionsIcon} alt='predictions-icon' className='w-[24px]'/>
                                <p className="font-medium">Predictions</p>
                            </button>
                        </Tooltip>

                        
                        {isReadacrossEnabled && (
                            <Tooltip title={`${!isNavOpen ? 'Read-Across' : ''}`} placement="right">
                                <button onClick={(e:MouseEvent<HTMLButtonElement>) => navigate('/readacross')} className={`hide text-nowrap ${pathName === 'readacross' ? 'bg-tertiary text-primary darken-icons' : 'bg-none text-white'} transition-all darken flex items-center gap-3 p-3 hover:text-primary hover:bg-tertiary rounded`}>
                                    <img src={ReadAcrossIcon} alt='readacross-icon' className='w-[24px]'/>
                                    <p className="font-medium">Read-Across</p>
                                </button>
                            </Tooltip>
                        )}

                        <Tooltip title={`${!isNavOpen ? 'Job Queue' : ''}`} placement="right">
                            <button onClick={(e:MouseEvent<HTMLButtonElement>) => navigate('/jobs')} className={`hide text-nowrap ${pathName === 'jobs' ? 'bg-tertiary text-primary darken-icons' : 'bg-none text-white'} transition-all darken flex items-center gap-3 p-3 hover:text-primary hover:bg-tertiary rounded`}>
                                <img src={JobsIcon} alt='jobs-icon' className='w-[24px]'/>
                                <p className="font-medium">Job Queue</p>
                            </button>
                        </Tooltip>

                            {auth && isAdmin(auth) && (
                                <Tooltip title={`${!isNavOpen ? 'User Directory' : ''}`} placement="right">
                                    <button onClick={(e:MouseEvent<HTMLButtonElement>) => navigate('/users')} className={`hide text-nowrap ${pathName === 'users' ? 'bg-tertiary text-primary darken-icons' : 'bg-none text-white'} transition-all darken flex items-center gap-3 p-3 hover:text-primary hover:bg-tertiary rounded`}>
                                        <img src={UsersIcon} alt='users-icon' className='w-[24px]'/>
                                        <p className="font-medium">User Directory</p>
                                    </button>
                                </Tooltip>
                            )}
                        </>
           


                    </div>

                    <div className='flex flex-col gap-4 w-full'>
                        <Tooltip title={`${!isNavOpen ? 'Help' : ''}`} placement="right">
                            <button onClick={(e: MouseEvent<HTMLButtonElement>) => {navigate('/help')}} className='hide transition-all darken flex items-center gap-3 p-3 hover:text-primary hover:bg-tertiary rounded'>
                                <img src={HelpIcon} alt='dashboard-icon' className='w-[24px]'/>
                                
                                    <div className="w-full whiten flex justify-between items-center">
                                        <p className="font-medium text-nowrap">Help Center</p>
                                        {/* <img src={OpenHelpIcon} alt='open-help-submenu-icon' className='w-[24px]'/> */}
                                    </div>
                                
                            </button>
                        </Tooltip>
                        
                        <Tooltip title={`${!isNavOpen ? 'Profile' : ''}`} placement="right">
                            <button onClick={(e:MouseEvent<HTMLButtonElement>) => navigate('/profile')} className='hide transition-all darken whiten flex items-center gap-3 p-3 hover:text-primary hover:bg-tertiary rounded'>
                                <img src={AccountIcon} alt='account-icon' className='w-[24px]'/>
                                <p className="font-medium">Profile</p>
                            </button>
                        </Tooltip>

                        <Tooltip title={`${!isNavOpen ? 'Logout' : ''}`} placement="right">
                            <button onClick={(e:any) => logout()} className='hide transition-all darken whiten flex items-center gap-3 p-3 hover:text-primary hover:bg-tertiary rounded'>
                                <img src={LogoutIcon} alt='logout-icon' className='w-[24px]'/>
                                <p className="font-medium">Logout</p>
                            </button>
                        </Tooltip>
                    </div>
                  
                </div>
            )}
            <div className={`${theme === 'dark' ? 'bg-dark-background text-white' : 'bg-darker-background text-primary'} ${location.pathname.includes('help') || !pathName.length ? 'p-0' : 'p-10'} w-full grow`}>
                <Outlet/>
            </div>
            <SnackBar message={errorMessage} setOpen={setOpen} open={open} isSuccess={false}/>
        </div>
    )
}