import Plot from 'react-plotly.js';
import { Config } from 'plotly.js';


export default function PlotlyGraphs({
  data, 
  type, 
  maxValue, 
  isSquare, 
  isClassification, 
  methodName, 
  total,
  threshold,
}: {
  data: Plotly.Data[], type: 'roc' | 'scatter' | 'histogram' | 'radar' | 'bar' | 'box' | 'singleRoc' | 'conf' | 'stack', 
  isSquare: boolean, 
  maxValue?: number, 
  isClassification?: boolean, 
  methodName?: string, 
  total?: number,
  threshold?: number,
}) {


    const config: Partial<Config> = {
        scrollZoom: false,
        displaylogo: false,
        displayModeBar: true,
        responsive: true,
        modeBarButtonsToRemove: ['toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian'],
        toImageButtonOptions: {
            format: 'svg',
            scale: 1,
        }
    }

    const layout_reg: () => Partial<Plotly.Layout> = function() {
        const combinedData = ((data[0] as Plotly.ScatterData).x as number[]).concat((data[0] as Plotly.ScatterData).y as number[]);
        const sortedData = combinedData.sort((a,b) => a - b);

        let low_value = Math.floor(Number(sortedData[0]));
        let high_value = Math.round(Number(sortedData[sortedData.length - 1]));
    
        return {
          title: {
            text: "Cross-Validation Predictions",
            yref: 'paper',
            xref: 'paper',
            yanchor: 'bottom',
            y: 1,
            pad: {
              b:10,
              l:0,
              r:0,
              t:10
            },
            xanchor: 'auto',
            font: {
                family: 'Montserrat',
                size: 16,
            }
          },
          xaxis: {range: [low_value, high_value]},
          yaxis:  {range: [low_value, high_value]},
          responsive: true,
          autosize: true,
          showlegend:false,
          margin: {
            l: 30,
            r: 10,
            b: 30,
            t: 60,
            pad: 0
          },
          modebar: {
            color: '#555555',
            activecolor: '#315FB5',
          },
          shapes: [
            {
              type: 'line',
              x0: low_value,
              y0: low_value,
              x1: high_value,
              y1: high_value,
              line: {
                color: '#999999',
                width: 2,
                dash: 'dot'
              }
            }
          ]
        }
    } 

    const layout_roc: () => Partial<Plotly.Layout> = function() {

        return {

            title: {
              text: type === 'roc' ? '5-fold Cross Validation ROC' : 'Cross Validation ROC',
              yref: 'paper',
              xref: 'paper',
              yanchor: 'bottom',
              y: 1,
              pad: {
                b:10,
                l:0,
                r:0,
                t:10
              },
              xanchor: 'auto',
              font: {
                family: 'Montserrat',
                size: 16,
              }
            },
            responsive: true,
            autosize: true,  
            showlegend:false,
            margin: {
              l: 30,
              r: 10,
              b: 30,
              t: 60,
              pad: 0
            },
            modebar: {
              color: '#555555',
              activecolor: '#315FB5',
            },
            shapes: [
              {
                type: 'line',
                x0: 0,
                y0: 0,
                x1: 1,
                y1: 1,
                line: {
                  color: '#999999',
                  width: 2,
                  dash: 'dot'
                }
              }
            ]

        }
    }


    const layout_hist: () => Partial<Plotly.Layout> = function() {

        return {
            barmode: "overlay", 
            title: {
              text: "Model Evaluation Overlay",
              yref: 'paper',
              xref: 'paper',
              yanchor: 'bottom',
              y: 1,
              pad: {
                b:10,
                l:0,
                r:0,
                t:10
              },
              xanchor: 'auto',
              font: {
                family: 'Montserrat',
                size: 16,
              }
            },
            responsive: true,
            autosize: true, 
            showlegend:false,
            margin: {
              l: 38,
              r: 10,
              b: 38,
              t: 60,
              pad: 0
            },
            modebar: {
              color: '#555555',
              activecolor: '#315FB5',
            },
            xaxis: {
                title: {
                    text: 'Value',
                    font: {
                        family: 'Montserrat',
                        size: 14,
                    },
                    
                }
            }, 
            yaxis: {
                title: {
                    text: 'Count',
                    font: {
                        family: 'Montserrat',
                        size: 14,
                    },
                }
            }, 
          }
        
    };


    const layout_radar: () => Partial<Plotly.Layout> = function() {
      return {
          polar: {
              radialaxis: {
                  visible: true,
                  range: [-1,1],
              }
          },
          legend: {
              font: {
                  size: 16,
              }
          }
      }
  }

  const layout_bar: () => Partial<Plotly.Layout> = function() {
    return {
        barmode: 'group',
        yaxis: {range: [-1,maxValue ? maxValue : 1], autorange: false, dtick: 0.2},
        showlegend: true,
        legend: {
            font: {
                size: 16,
            }
        },
        shapes: maxValue ? [] : [
            {
                type: 'line',
                x0: 0,
                y0: maxValue ? maxValue : 1,
                x1: 1,
                y1: maxValue ? maxValue : 1,
                xref: 'paper',
                yref: 'y',
                line: {
                    color: 'blue',
                    width: 3,
                    dash: 'dot',
                }
            }
        ]
    }
  };

  const layout_pred: () => Partial<Plotly.Layout> = function()  {
    return {
        xaxis: {
            title: {
                text: isClassification ? 'Probability-Like Scores' : '-logM Activity' ,
                standoff: 40
            },
            zeroline:false
        },
        yaxis: {
            title: {
                text: 'Molecule',
                standoff: 40
            },
            showgrid: true,
            gridwidth: 1,
            gridcolor: 'rgb(243,243,243)',
            zeroline: false
        },
        margin: {
            l: 130,
            r: 60,
            t: 60,
            b: 100,
            pad: 10
        },
        autosize: true,
        showlegend: false,
        font: {
            color: '#333333',
            family: 'Trebuchet MS, Tahoma, Arial'
        },
        dragmode: 'pan',
        modebar: {
            color: '#555555',
            activecolor: '#315FB5',
            orientation: 'h'
        },
        hovermode: "closest",
        hoverlabel: { bgcolor: "#FFF" },
    }

}

const layout_conf: () => Partial<Plotly.Layout> = function() {
  return {
    barmode: 'overlay',
    title: {
      text:  methodName ? methodName.toLocaleLowerCase() : 'Conformal Predictor',
      yref: 'paper',
      xref: 'paper',
      yanchor: 'bottom',
      y: 1,
      pad: {
        b:10,
        l:0,
        r:0,
        t:10
      },
      xanchor: 'auto',
      font: {
        family: 'Montserrat',
        size: 16,
      }
    },
    xaxis: {
        title: 'Alpha Score',
        
        
    },
    yaxis: {
        title: 'Frequency',
        range: [0, total ? total : 100]
    },
    bargap: 0,
    responsive: true,
    autosize: true,
    showlegend:false,
    margin: {
      l: 40,
      r: 10,
      b: 40,
      t: 60,
      pad: 0
    },
  }
}

const layout_stack: () => Partial<Plotly.Layout> = function() {
  return {
      title: `Conformal Predictor Results - ${threshold ? threshold : 0.5}`,
      barmode: 'stack'
  }
}


  const getPlotLayout = () => {
    if (type === 'bar') {
      return layout_bar()
    } else if (type === 'radar') {
      return layout_radar()
    } else if (type === 'roc') {
      return layout_roc()
    } else if (type === 'histogram') {
      return layout_hist()
    } else if (type === 'box') {
      return layout_pred()
    } else if (type === 'singleRoc') {
      return layout_roc() 
    } else if (type === 'conf') {
      return layout_conf()
    } else if (type === 'stack') {
      return layout_stack();
    }  else {
      return layout_reg()
    }
  }




    return (
        <Plot data={data} layout={getPlotLayout()} config={config} className={`w-full ${isSquare && 'aspect-square'} ${!isSquare && 'h-[600px]'}`}/>
    )
}