import { Alert } from '@mui/material';

export default function ErrorMessage({ errorMessage }: { errorMessage: string }) {
    return (
        <Alert severity='error' sx={{
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 500,
        }}>{errorMessage}</Alert>
    )
}