
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ChevronDown from '../../../assets/chevron-down.svg';
import ChevronUp from '../../../assets/chevron-up.svg'

const sx = {
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    color: '#35424A',
    padding: '12px 20px',
}


export default function MultipleModelDropdown({ modelNames, selectedName, changeTab }: {
    modelNames: string[],
    selectedName: string,
    changeTab: (name: string) => void,
}) {
   
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

   

    return (
        <div>
            <button onClick={handleClick}>
                <div className={`transition-all bg-grad-two hover:brightness-90 text-primary flex justify-center items-center gap-3 py-3 px-5 rounded w-full`}>
                    <p className='font-medium text-nowrap max-w-[300px] truncate'>{selectedName}</p>
                    {open ? <img src={ChevronUp} alt='close-dropdown-icon' /> : <img src={ChevronDown} alt='open-dropdown-icon' />}
                </div>
            </button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    marginTop: '8px',
                    '& .MuiMenu-list': {
                        padding: 0,
                    }
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {modelNames.filter(name => name !== selectedName).map(model => (
                    <MenuItem sx={sx} onClick={(e:any) => {changeTab(model); handleClose()}}><span className='text-nowrap'>{model}</span></MenuItem>

                ))}
            </Menu>
        </div>
         
    )
}