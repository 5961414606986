import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';

import { setSelectedFile } from '../../../store/slices/files.slice';
import { selectFiles } from '../../../store/selectors/files.selectors';

import usePrivileges from '../../../hooks/usePrivileges';
import { CircularProgress } from '@mui/material';
import { PopupType } from '../../../hooks/usePopup';

const sx = {
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    color: '#35424A',
    padding: '12px 20px',
}

export default function IndividualFileOptions({ togglePopup, setPopupType, id, downloadFunc, application, handleClose, addSingleItem }: { 
    togglePopup: (e:any) => void,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
    id?: string,  
    downloadFunc: (filename: string, id: string, ext?: string) => Promise<void>,
    application: string,
    handleClose: () => void,
    addSingleItem?: (id: string) => void,
}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ isDownloading, setIsDownloading ] = useState(false);
    const [ isXLSXDownloading, setIsXLSXDownloading ] = useState(false);

    const files = useSelector(selectFiles);
    const { canCreate, canTrain, canPredict, canDelete } = usePrivileges();
    const [ formats, setFormats ] = useState<string[]>([]);

    useEffect(() => {
        if (id) {
            const file = files.find(f => f._id.$oid === id);
            if (file) {
                const ext = file.name.split('.')[1];
                if (ext === 'xlsx') {
                    setFormats(['xlsx'])
                } else {
                    setFormats([ext, 'xlsx']);
                }
            }
        }
    },[files, id]);

    const selectSingleFile = (id?: string) => {
        if (id) {
            const file = files.find(f => f._id.$oid === id);
            if (file) {
                dispatch(setSelectedFile(file));
            }
        }
    };

    const downloadItem = async (e:MouseEvent<HTMLButtonElement>, ext?: string) => {
        e.preventDefault();
        if (id) {
            const file = files.find(f => f._id.$oid === id);
            if (file) {
                if (ext !== undefined) {
                    setIsXLSXDownloading(true);
                } else {
                    setIsDownloading(true);
                }

                await downloadFunc(file.name && ext !== undefined ? `${file.name.split('.')[0]}.${ext}` : file.name ?  file.name : 'file.csv', id, ext);
                
                if (ext !== undefined) {
                    setIsXLSXDownloading(false);
                } else {
                    setIsDownloading(false);
                }
            } 
        }

        handleClose();
    }


    return (
        <>
            {canCreate && application && (application).includes('assay-central') &&  <MenuItem sx={sx} onClick={(e:any) => {togglePopup(e); setPopupType('create-dataset'); selectSingleFile(id); handleClose()}}>Create New Dataset</MenuItem>}
            {canTrain && application && (application).includes('assay-central') && <MenuItem sx={sx} onClick={(e:any) => {selectSingleFile(id); navigate('/models/create-new-model?dataset=new'); handleClose()}}>Train New Model</MenuItem>}
            {canPredict && <MenuItem sx={sx} onClick={(e:any) => {selectSingleFile(id); navigate('/predictions/make-new-prediction?type=set'); handleClose()}}>Make New Prediction</MenuItem>}
            {formats.length > 0 && formats.map(format => (
                <MenuItem sx={sx} onClick={(e:any) => {format === 'xlsx' ? downloadItem(e, 'xlsx') : downloadItem(e)}} disabled={format === 'xlsx' ? isXLSXDownloading : isDownloading}>
                    <div className='flex justify-start items-center gap-2'>
                        {(format === 'xlsx' ? isXLSXDownloading : isDownloading) && <CircularProgress size={12} color='inherit' />}
                        <p>Export {`(.${format})`}</p>
                    </div>
                </MenuItem>
            ))}
            {/* <MenuItem sx={sx} onClick={(e:any) => {downloadItem(e)}} disabled={isDownloading}>
                <div className='flex justify-start items-center gap-2'>
                    {isDownloading && <CircularProgress size={12} color='inherit' />}
                    <p>Export</p>
                </div>
            </MenuItem> */}
            {canDelete(id!) && <MenuItem sx={sx} onClick={(e:any) => {togglePopup(e); setPopupType('delete'); addSingleItem && addSingleItem(id ? id : ''); handleClose()}}>Delete</MenuItem>}
        </>
           
    )
}