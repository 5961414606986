import { Link } from "react-router-dom";
import Right from '../../assets/chevron-right-black.svg';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
import { selectTheme } from "../../store/selectors/auth.selector";
import { ReactNode } from "react";
import TruncatedText from "./TruncatedText";

function truncate(str:string, n:number){
    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
  };

const getStatusColor = (status: string) => {
    switch(status) {
        case "Pending": 
            return '#FFF278';
        
        case "Rescheduled":
            return '#FFF278';
            
        case "Running":
            return '#FFC061';

        case "Done":
            return '#C7DB78';

        case "Failed":
            return '#FF8484';

        default:
            return '#EEEEEE';
    }
}

export default function SinglePageHeader({ status, isLoading, id, routes, label, children, metadata }: {
    status?: string | null,
    isLoading?: boolean,
    id?: string,
    routes: {label: string, path:string}[],
    label: string,
    children?: ReactNode,
    metadata?: ReactNode
}) {
    const theme = useSelector(selectTheme);

    return (
        <div className="w-full flex justify-between items-start mb-4 gap-10">
            <div className='flex flex-col justify-start items-start gap-4 grow'>
                {isLoading ? (
                <>
                    <div className="w-full"><Skeleton height={14}/></div>
                    <div className="w-full"><Skeleton height={42}/></div>   
                </>
                ) : (            
                <>
                    <div className={`flex justify-center items-center gap-1 ${theme === 'dark' && 'whiten'}`}>
                    {routes.map((r:{label:string,path:string}, i:number) => (
                        <>
                            <Link to={`/${r.path}`} className={`text-[14px] font-medium hover:underline transition-all ${i === routes.length-1 && 'text-tertiary'}`}>{truncate(r.label, 50)}</Link>
                            {i < routes.length-1 && <img src={Right} alt='chevron-right-icon' className={`w-[12px]`}/>}
                        </>
                    ))}
                    </div>
                    <div className="flex flex-col gap-1 justify-start items-start">
                        <div className="flex justify-start items-start gap-4">
                            <TruncatedText text={label} alignment="left">
                            <p className="font-semibold text-[2rem] max-w-[800px] truncate">
                                {label}
                            </p>
                            </TruncatedText>
                            {status && (
                                <div style={{backgroundColor: `${getStatusColor(status)}`}} className="rounded-full py-2 px-4 mt-1">
                                    <p className="font-semibold text-primary">{status}</p>
                                </div>
                            )}
                        </div>
                        {metadata}
                    </div>
                    
                </>
                )}
            </div>
            <div id={id} className='flex gap-3'>
                {children}
            </div>
        </div>
    )
}